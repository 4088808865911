<template>
  <div
    class="bg fill-height"
  >
    <v-container
      fluid
      class="fill-height ma-0 pa-0"
    >
      <v-row
        align="center"
        justify="center"
        class="fill-height pa-0 ma-0"
      >
        <v-card
          elevation="17"
          raised
          class="pa-5 ma-1"
          width="380px"
        >
          <v-container class="ma-0 logo-wrapper">
            <img src="../assets/mappa_logo.png"/>
          </v-container>
          <v-card-title>{{ cardTitleText }}</v-card-title>
          <v-card-text>

            <v-form
              name="recover-password-form"
              novalidate
              :value="valid"
              ref="recover-password-form"
              @submit.prevent="mainButtonAction"
            >
              <v-container
                fluid
                class="pa-0"
              >
                <v-row
                  v-if="currentStage === ADD_EMAIL"
                  class="ma-0"
                >
                  <v-text-field
                    color="accent"
                    label="Insira e-mail utilizado no cadastro"
                    outlined
                    v-model="recoverEmail"
                    :error-messages="recoverEmailErrors"
                    class="mx-0 mt-4"
                  >
                  </v-text-field>
                </v-row>
                <v-row
                  v-else-if="currentStage === RESET_PASSWORD"
                  class="ma-0"
                >

                  <v-row
                    class="ma-0"
                  >
                    <v-text-field
                      id="code"
                      name="code"
                      color="accent"
                      label="Insira código enviado no seu e-mail"
                      outlined
                      class="mx-0 mt-4"
                      v-model="recoverToken"
                      v-mask="codeMask"
                      :error-messages="recoverTokenErrors"
                    >
                    </v-text-field>
                  </v-row>
                  <v-text-field
                    id="new-password"
                    name="new-password"
                    color="accent"
                    label="Nova senha"
                    outlined
                    class="mx-0"
                    v-model="newPassword"
                    :error-messages="newPasswordErrors"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'password': 'text' "
                    @click:append="showPassword = !showPassword"
                  >
                  </v-text-field>
                  <v-text-field
                    id="new-password-repeat"
                    name="new-password-repeat"
                    color="accent"
                    label="Repita a nova senha"
                    outlined
                    class="mx-0"
                    v-model="newPasswordRepeat"
                    :error-messages="newPasswordRepeatErrors"
                    :append-icon="showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPasswordRepeat ? 'password': 'text' "
                    @click:append="showPasswordRepeat = !showPasswordRepeat"
                  >
                  </v-text-field>
                </v-row>
                <v-row
                  v-else-if="currentStage === RESET_SUCCESS"
                  class="ma-0"
                >
                  <span class="subtitle-1 mb-4">Sua senha foi alterada com sucesso.<br/>Retorne ao login e utilize a nova senha definida.</span>
                </v-row>
                <v-row
                  v-else-if="currentStage === RESET_FAILED"
                  class="ma-0"
                >
                  <span class="subtitle-1 mb-4"> Infelizmente não foi possível alterar sua senha.<br/>Você pode tentar novamente a qualquer momento. </span>
                </v-row>
              </v-container>
              <v-btn
                block
                type="submit"
                color="accent"
                :loading="isLoading"
                class="mx-0 my-2"
              >
                {{mainButtonText}}
              </v-btn>
            </v-form>
          </v-card-text>
          <div v-if="currentStage === ADD_EMAIL">
            <v-divider
              class="my-4"
            >

            </v-divider>
            <v-card-actions
              class="pa-0"
            >
              <v-container
                fluid
                class="pa-0 pt-1"
              >
                <v-btn
                  to="/login"
                  small
                  block
                  text
                  color="primary"
                  class="mx-0 mb-4"
                >
                  Voltar ao login
                </v-btn>
                <v-btn
                  to="/register"
                  small
                  block
                  text
                  color="primary"
                  class="mx-0 mt-4"
                >
                  Não sou cadastrado
                </v-btn>
              </v-container>
            </v-card-actions>
          </div>
        </v-card>
      </v-row>

    </v-container>
  </div>
</template>

<script>
  import {validationMixin} from "vuelidate"
  import {email, requiredIf} from "vuelidate/lib/validators"
  import {ResetPasswordExpiredToken, ResetPasswordInvalidToken} from "../helpers/auth-horus"

  export default {
    name: "RecoverPassword",
    mixins: [validationMixin],
    data: () => ({
      currentStage: null,
      isLoading: false,


      // Email stage fields
      recoverEmail: null,

      // Code stage fields
      recoverToken: null,
      newPassword: null,
      newPasswordRepeat: null,
      showPassword: true,
      showPasswordRepeat: true,

      isTokenInvalid: false,

      // Reset Failed fields
      resetErrorMotive: null,

      // Stage Consts
      ADD_EMAIL: "ADD_EMAIL",
      RESET_PASSWORD: "RESET_PASSWORD",
      RESET_SUCCESS: "RESET_SUCCESS",
      RESET_FAILED: "RESET_FAILED",
    }),
    validations: {
      // Email stage fields
      recoverEmail: {
        required: requiredIf(function () {
          return this.currentStage === this.ADD_EMAIL
        }),
        email,
      },

      // Code stage fields
      recoverToken: {
        required: requiredIf(function () {
          return this.currentStage === this.RESET_PASSWORD
        }),
        invalidToken() {
          return !this.isTokenInvalid
        },
      },
      newPassword: {
        required: requiredIf(function () {
          return this.currentStage === this.RESET_PASSWORD
        }),
      },
      newPasswordRepeat: {
        required: requiredIf(function () {
          return this.currentStage === this.RESET_PASSWORD
        }),
        passwordMatch() {
          return this.newPassword === this.newPasswordRepeat
        },
      },
    },
    methods: {
      requestRecoverPassword() {
        this.$v.$touch()
        if (this.$v.$invalid) return

        this.isLoading = true
        this.$apiAuth.requestResetPassword(this.recoverEmail)
          .then(() => {
            this.currentStage = this.RESET_PASSWORD
            this.$v.$reset()
          })
          .finally(() => {
            this.isLoading = false
          })
      },
      resetPassword() {
        this.isTokenInvalid = false

        this.$v.$touch()
        if (this.$v.$invalid) return

        this.isLoading = true
        const recoverToken = this.recoverToken.trim()
        this.$apiAuth.resetPassword(recoverToken, this.newPassword)
          .then(() => {
            this.currentStage = this.RESET_SUCCESS
            this.$v.$reset()
          })
          .catch((err) => {
            if (err instanceof ResetPasswordExpiredToken) {
              this.currentStage = this.RESET_FAILED
              this.resetErrorMotive = "Código expirado"
            } else if (err instanceof ResetPasswordInvalidToken) {
              this.isTokenInvalid = true
            } else {
              this.currentStage = this.RESET_FAILED
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      },
      redirectToLogin() {
        this.$router.push("/login")
      },
      retryResetPassword() {
        this.$router.go(0)
      },
    },
    computed: {
      cardTitleText() {
        switch (this.currentStage) {
          case this.ADD_EMAIL:
            return "Recuperação de senha"
          case this.RESET_PASSWORD:
            return "Recuperação de senha"
          case this.RESET_SUCCESS:
            return "Senha alterada com sucesso"
          case this.RESET_FAILED:
            return "Falha ao alterar senha"
          default:
            return ""
        }
      },
      mainButtonText() {
        switch (this.currentStage) {
          case this.ADD_EMAIL:
            return "Recuperar senha"
          case this.RESET_PASSWORD:
            return "Trocar senha"
          case this.RESET_SUCCESS:
            return "Ir para o login"
          case this.RESET_FAILED:
            return "Tentar novamente"
          default:
            return ""
        }
      },
      mainButtonAction() {
        switch (this.currentStage) {
          case this.ADD_EMAIL:
            return this.requestRecoverPassword
          case this.RESET_PASSWORD:
            return this.resetPassword
          case this.RESET_SUCCESS:
            return this.redirectToLogin
          case this.RESET_FAILED:
            return this.retryResetPassword
          default:
            return () => {
            }
        }
      },

      // Validation errors
      recoverEmailErrors() {
        const errors = []
        if (!this.$v.recoverEmail.$dirty) return errors
        !this.$v.recoverEmail.required && errors.push("Campo obrigatório")
        !this.$v.recoverEmail.email && errors.push("Email inválido.")
        return errors
      },
      recoverTokenErrors() {
        const errors = []
        if (!this.$v.recoverToken.$dirty) return errors
        !this.$v.recoverToken.required && errors.push("Campo obrigatório")
        !this.$v.recoverToken.invalidToken && errors.push("Token inválido.")
        return errors
      },
      newPasswordErrors() {
        const errors = []
        if (!this.$v.newPassword.$dirty) return errors
        !this.$v.newPassword.required && errors.push("Campo obrigatório")
        return errors
      },
      newPasswordRepeatErrors() {
        const errors = []
        if (!this.$v.newPasswordRepeat.$dirty) return errors
        !this.$v.newPasswordRepeat.required && errors.push("Campo obrigatório")
        !this.$v.newPasswordRepeat.passwordMatch && errors.push("As senhas precisam ser iguais.")
        return errors
      },
      codeMask() {
        return ["XXXXXX"]
      }
    },
    created() {
      this.currentStage = this.ADD_EMAIL
    },
  }
</script>

<style scoped lang="scss">
  @import "../sass/mixins";

  .bg {
    background-image: url("../assets/login/background_image.jpg");
    background-size: cover;
  }

  .v-card {
    max-width: 380px;

    @include display-xs-only {
      width: 100% !important;
      min-height: 100% !important;
      padding: 10px;
      margin: 0px !important;
    }
  }

  .logo-wrapper {
    text-align: center;
  }

  .logo-wrapper img {
    height: 70px;
  }

</style>
