var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg fill-height" },
    [
      _c(
        "v-container",
        { staticClass: "fill-height ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "fill-height pa-0 ma-0",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-5 ma-1",
                  attrs: { elevation: "17", raised: "", width: "380px" }
                },
                [
                  _c("v-container", { staticClass: "ma-0 logo-wrapper" }, [
                    _c("img", {
                      attrs: { src: require("../assets/mappa_logo.png") }
                    })
                  ]),
                  _c("v-card-title", [_vm._v(_vm._s(_vm.cardTitleText))]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "recover-password-form",
                          attrs: {
                            name: "recover-password-form",
                            novalidate: "",
                            value: _vm.valid
                          },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.mainButtonAction($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "pa-0", attrs: { fluid: "" } },
                            [
                              _vm.currentStage === _vm.ADD_EMAIL
                                ? _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mx-0 mt-4",
                                        attrs: {
                                          color: "accent",
                                          label:
                                            "Insira e-mail utilizado no cadastro",
                                          outlined: "",
                                          "error-messages":
                                            _vm.recoverEmailErrors
                                        },
                                        model: {
                                          value: _vm.recoverEmail,
                                          callback: function($$v) {
                                            _vm.recoverEmail = $$v
                                          },
                                          expression: "recoverEmail"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm.currentStage === _vm.RESET_PASSWORD
                                ? _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "ma-0" },
                                        [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: _vm.codeMask,
                                                expression: "codeMask"
                                              }
                                            ],
                                            staticClass: "mx-0 mt-4",
                                            attrs: {
                                              id: "code",
                                              name: "code",
                                              color: "accent",
                                              label:
                                                "Insira código enviado no seu e-mail",
                                              outlined: "",
                                              "error-messages":
                                                _vm.recoverTokenErrors
                                            },
                                            model: {
                                              value: _vm.recoverToken,
                                              callback: function($$v) {
                                                _vm.recoverToken = $$v
                                              },
                                              expression: "recoverToken"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("v-text-field", {
                                        staticClass: "mx-0",
                                        attrs: {
                                          id: "new-password",
                                          name: "new-password",
                                          color: "accent",
                                          label: "Nova senha",
                                          outlined: "",
                                          "error-messages":
                                            _vm.newPasswordErrors,
                                          "append-icon": _vm.showPassword
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.showPassword
                                            ? "password"
                                            : "text"
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            _vm.showPassword = !_vm.showPassword
                                          }
                                        },
                                        model: {
                                          value: _vm.newPassword,
                                          callback: function($$v) {
                                            _vm.newPassword = $$v
                                          },
                                          expression: "newPassword"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        staticClass: "mx-0",
                                        attrs: {
                                          id: "new-password-repeat",
                                          name: "new-password-repeat",
                                          color: "accent",
                                          label: "Repita a nova senha",
                                          outlined: "",
                                          "error-messages":
                                            _vm.newPasswordRepeatErrors,
                                          "append-icon": _vm.showPasswordRepeat
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.showPasswordRepeat
                                            ? "password"
                                            : "text"
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            _vm.showPasswordRepeat = !_vm.showPasswordRepeat
                                          }
                                        },
                                        model: {
                                          value: _vm.newPasswordRepeat,
                                          callback: function($$v) {
                                            _vm.newPasswordRepeat = $$v
                                          },
                                          expression: "newPasswordRepeat"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm.currentStage === _vm.RESET_SUCCESS
                                ? _c("v-row", { staticClass: "ma-0" }, [
                                    _c(
                                      "span",
                                      { staticClass: "subtitle-1 mb-4" },
                                      [
                                        _vm._v(
                                          "Sua senha foi alterada com sucesso."
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "Retorne ao login e utilize a nova senha definida."
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.currentStage === _vm.RESET_FAILED
                                ? _c("v-row", { staticClass: "ma-0" }, [
                                    _c(
                                      "span",
                                      { staticClass: "subtitle-1 mb-4" },
                                      [
                                        _vm._v(
                                          " Infelizmente não foi possível alterar sua senha."
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "Você pode tentar novamente a qualquer momento. "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-0 my-2",
                              attrs: {
                                block: "",
                                type: "submit",
                                color: "accent",
                                loading: _vm.isLoading
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.mainButtonText) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.currentStage === _vm.ADD_EMAIL
                    ? _c(
                        "div",
                        [
                          _c("v-divider", { staticClass: "my-4" }),
                          _c(
                            "v-card-actions",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-container",
                                {
                                  staticClass: "pa-0 pt-1",
                                  attrs: { fluid: "" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-0 mb-4",
                                      attrs: {
                                        to: "/login",
                                        small: "",
                                        block: "",
                                        text: "",
                                        color: "primary"
                                      }
                                    },
                                    [_vm._v(" Voltar ao login ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-0 mt-4",
                                      attrs: {
                                        to: "/register",
                                        small: "",
                                        block: "",
                                        text: "",
                                        color: "primary"
                                      }
                                    },
                                    [_vm._v(" Não sou cadastrado ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }